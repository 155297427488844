import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';

export default function TermService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="page-content">
        <div className="container privacy-wrapper py-5">
            <div className="wrappere pb-5">
                <h1>Term Services</h1>
                <h2 className="h3 mb-5">Continuum Insights</h2>
                <h4 className="mb-4">1. Who we are and what we do</h4>
                <h5>Who are we?</h5>
                <div>
                    <strong>We are Continuum Insights (“Continuum”, “us”, “we”,
                        “our”)</strong>
                    and we own and operate
                    <NavLink to="https://www.websamp.com/">(“WEBSAMP”)</NavLink> . We are a
                    proprietorship firm registered in Haryana, with our office at 5th
                    Floor, 501, JMD Megapolis IT Park, Sohna Road, Sector 48, Gurugram –
                    122018, Haryana, India We are also registered in Ontario, Canada,
                    under the Ontario Corporation No. 1000921420, our registered office
                    is 895 Don Mills Road, Tower 2, Suite 108 Road, Toronto, Ontario,
                    M3C 1W3, Canada. <br />
                </div>
                <h5>What we do</h5>
                <p>
                    Continuum specializes in collecting market research survey data
                    through online and telephonic methods. We assist clients across
                    various sectors, verticals, and regions in gathering high-quality
                    data to gain valuable consumer insights. Our services include
                    building and managing panels of survey participants, both B2B and
                    B2C, who provide feedback and opinions on different brands. <br />
                    We ensure the protection and management of personal data shared with
                    us upon signing up on the WEBSAMP website. As a member of ESOMAR and
                    the Market Research Society of India (MRSI), we adhere to the
                    highest standards of conduct and follow relevant codes and
                    guidelines to manage our online panel and handle personal data
                    securely.
                </p>
            </div>

            <div className="wrapperepb-5">
                <h4>2. Our Privacy Commitment</h4>
                <div>
                    Personal Information is key and we value you sharing that with us
                    and we honour that trust. This privacy notice will guide you in
                    detail about our processes of how we collect, use, share and secure
                    personal data you provide on our website, when you sing up to become
                    our member and participate in surveys and/or research studies
                    ("surveys”) that we conduct for, and on behalf of our clients. This
                    section will also explain your privacy rights and how laws that are
                    applicable to you may protect you and is intended to supplement
                    other notices and privacy policies and not to override them. The
                    sign up, use of and access to your membership and use of our
                    technology and tools (“Services”) is subject to this privacy notice.
                    Additionally, this privacy notice is provided in stacked format, so
                    that you access different sections and get more details.
                </div>
            </div>

            <div className="wrappere pb-5">
                <h4>3. Who this privacy notice applies to</h4>
                <p><strong>This privacy notice applies to you if:</strong></p>
                <ul>
                    <li>You register an account on our website</li>
                    <li>You partake in surveys on our website;</li>
                    <li>You use our app</li>
                    <li>
                        You sign up to receive newsletters and/or other promotional
                        communications from us.
                    </li>
                </ul>

                <h4>4. What Personal Data is</h4>
                <div>
                    In this privacy notice, our use of the term “personal data” includes
                    other similar terms under applicable privacy laws, such as “personal
                    information” and “personally identifiably information”. In general,
                    personal data means any information that identifies, relates to,
                    describes, or is reasonably capable of being associated with an
                    individual (the data subject).
                </div>

                <div>
                    <strong>SENSITIVE PERSONAL DATA</strong> - Our use of the term
                    ‘Special Category Personal Data’ also includes other similar terms,
                    such as “sensitive personal data” and “sensitive personal
                    information”. In general, Special category personal data is more
                    sensitive Personal Data and includes information revealing racial or
                    ethnic origin, political opinions, religious or philosophical
                    beliefs, trade union membership, genetic data, biometric data for
                    the purposes of uniquely identifying someone, data concerning
                    physical or mental health or data concerning someone’s sex life or
                    sexual orientation and requires an added level of protection.
                </div>
                <h4>
                    5. WHAT PERSONAL DATA DO WE COLLECT ABOUT YOU AND HOW DO WE USE YOUR
                    PERSONAL DATA?
                </h4>
                <div>
                    When you register with WEBSAMP, we ask for some basic personal
                    information. After registration, we may request additional details
                    to help us match you with appropriate surveys. The specifics of the
                    personal data we collect are detailed in the table below. Rest
                    assured, your data will only be used for the purposes stated and not
                    for modeling, analytics, or data matching. <br />
                    If third parties are involved, we ensure your data is kept secure
                    and confidential, and it will be erased according to our data
                    retention and destruction policies outlined in our Privacy Notice.
                    We follow legal guidelines and regulations regarding personal data
                    use. Here are the legal bases we rely on:
                </div>

                <ul>
                    <li>
                        Consent: We may collect and process your data with your consent,
                        such as when you agree to participate in surveys.
                    </li>
                    <li>
                        Contractual Obligations: We process your data to fulfill
                        contractual obligations, like sending rewards. Legal Compliance:
                        We might need to collect or process your data to comply with legal
                        requirements or respond to requests from authorities, or to
                        protect our rights and your safety.
                    </li>
                    <li>
                        Legitimate Interest: We process data based on our business
                        interests, ensuring we provide the best service while considering
                        the impact on your rights. We do not use your data in ways that
                        override your interests unless required by law or with your
                        consent.
                    </li>
                </ul>
            </div>

            <div className="table-wrapper">
                <div className="table-responsive-xl">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th>Purpose</th>
                                <th>Data</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    <NavLink>Your Membership</NavLink>
                                </td>
                                <td>
                                    <div>
                                        <div>
                                            To register and process your application to be a Member
                                        </div>
                                        <div>
                                            To register and process your application to be a Member
                                            To manage our relationship with you, which will include:
                                            Processing and delivering your points or rewards,
                                            exchange your points for vouchers/coupons and entering
                                            you in for prize draws/sweepstakes, including sharing
                                            your personal data with trusted third-party vendors to
                                            provide you with incentives on our behalf; Notifying you
                                            about changes to our terms or privacy policy; Asking you
                                            to leave a review or inviting you to take a survey; or
                                            For any other reason connected with your Membership.
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div>(a) Identity data</div>
                                        <div>(b) Contact</div>
                                        <div>(c) Demographic/Profile</div>
                                        <div>(e) Technical Data</div>
                                        <div>(f) Pseudonymised data</div>
                                        <div>(panellist ID)</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Market research surveys</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We will ask you to participate in and provide feedback via
                                        surveys, including you telling us about your preferences
                                        and consumer needs. This may also include analysing your
                                        behaviour in different ways and for different purposes,
                                        but only provided such uses are for market research. We
                                        may with your consent via a survey ask you to disclose
                                        certain Special Categories of Personal data, but only if
                                        relevant to that survey and as may be allowed under
                                        applicable law.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Identity<br />(b) Contact<br />(c) Special Categories
                                        of Personal Data.<br />(d) Demographic/Profile<br />(e)
                                        Technical data<br />(f) Pseudonmyised data (panellist ID)
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>
                                        <div>
                                            Scientific research/Safety monitoring (Pharmacovigilan
                                            ce Adverse Events Reporting)
                                        </div>
                                    </NavLink>
                                </td>
                                <td>
                                    <div>
                                        We may ask you to participate in surveys for clients who
                                        are from a variety of types of organisations such as;
                                        public health organisations, commercial or charity
                                        organisations or academics from universities, etc and may
                                        be conducted using a variety of methods.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Identity<br />(b) Contact<br />(c) Special Categories
                                        of<br />
                                        personal data (health,<br />
                                        e.g. disease, condition,<br />
                                        treatment, consumer<br />
                                        products and adverse events).<br />(d) Demographic/Profile
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Mobile Device surveys and usage</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We use a mobile app, which may in the future with your
                                        permission, include location-based geo fencing. If you
                                        agree to download and use our mobile application on your
                                        mobile phone, tablet or pc (device), we collect the
                                        following information: for administering the site,
                                        tracking users’ movements around the site and the
                                        Internet. We use mobile analytics software to allow us to
                                        better understand the functionality of our mobile app on
                                        your device. This software may record information such as
                                        how often you use the app, the events that occur within
                                        the app, aggregated usage, performance data, and where the
                                        app was downloaded from; for quality checking, fraud or
                                        other legal reasons we refer to in this privacy notice;
                                        Technical data and Demographic/Profile data are collected
                                        when you sign up to use our Mobile app and via the surveys
                                        you undertake. We use this data for data matching and
                                        enriching purposes and in ad-effectiveness campaigns for
                                        our clients; to gather demographic information about our
                                        user base as a whole, including for example, more
                                        generally for analysing trends of marketing research
                                        studies. Our clients may combine that information with
                                        those of others to produce “aggregated” reports. They may
                                        also create scientific reports based upon modelled
                                        information. “Modelled information” is data developed
                                        based upon demographic and behavioural characteristics
                                        (like your gender, age, and preferences) to predict what
                                        people with similar or matching characteristics would
                                        watch or buy. We may receive reports based on the use of
                                        these technologies by these companies on an individual as
                                        well as aggregated basis.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Demographic/Profil e data <br />
                                        (b) Technical data (collected automatically - system and
                                        performance information on the type of device you use,
                                        operating system version, device identifier and hardware
                                        usage statistics such as CPU and app version and
                                        Geo-location) <br />
                                        (c) Pseudonymised data
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><NavLink>Profiling</NavLink></td>
                                <td>
                                    <div>
                                        We may use your Demographic/Profile data for profiling
                                        purposes and (if you previously gave us your
                                        ethnicity/racial profile), we may also use those
                                        information for profiling purposes, provided this is
                                        permitted under local law. This means we will match you
                                        with appropriate surveys to see if you qualify for
                                        particular surveys. Your birth date is automatically
                                        updated so that we may ensure we select individuals, based
                                        on their age as may be required for the relevant survey.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Special Categories of personal data (Ethnicity/Racial
                                        profile)<br />(b) Demographic/Profile
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Data matching and enrichment</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We may from time to time share some of your personal data
                                        with select and trusted third party processors. Those
                                        parties may append analytics or demographics data they
                                        previously collected about you, which may be public
                                        sources (e.g. property ownership) and/or or private
                                        sources (e.g. subscriber lists or retail purchases, with
                                        whom you have an account). We will only share your
                                        Identity and Contact data with your prior consent, which
                                        may be as part of a survey, or an invitation to
                                        participate in such programs. We may also use your
                                        Pseudonymised data and/or Demographic/Profile data to add
                                        to existing anonymous data sets or create new ones. We use
                                        them so we can enrich the data we hold about you to
                                        improve your panel profile, your panel Membership
                                        experience and to ensure you are selected for relevant
                                        surveys.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a)Identity <br />
                                        (b)Contact <br />
                                        (c)Demographic/Profile data <br />
                                        (d) Technical data (e.g. IP address, ADID’s, browser and
                                        device information (incl. Mobile Device ID’s, App
                                        Technical data, usage, performance, data and location
                                        data)) <br />
                                        (e) Pseudonymised data
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Ad-Effectiveness campaigns & look-a-likes</NavLink>
                                </td>
                                <td>
                                    <div>
                                        To measure ad-effectiveness and/or to create ‘look-a-like’
                                        groups that have common Demographics/Profile data (or
                                        interests). Our clients then find people who are similar
                                        to those groups and use that information in a way that
                                        reaches new potential consumers and so helps them improve
                                        their advertising targeting and their online advertising
                                        models. We may share your unique panellist ID (UID) with
                                        our clients, who may write, set or read cookies or use
                                        other Technical data about you from multiple locations,
                                        including our own servers or systems. If you participate,
                                        your UID will be stored or associated with those Technical
                                        data to allow us and/or our clients to conduct online
                                        ad-effectiveness campaigns, track your visits to websites,
                                        use your Demographic/Profile data to create look-a-like
                                        segments and/or gain other online insights about you. If
                                        you have interacted with the online ad or promotion, our
                                        client will send your UID and the specific survey to us
                                        and we may provide you with the opportunity to complete
                                        the survey. We will not transfer any Identity or Contact
                                        data to our clients unless we have first received your
                                        consent and no third parties may contact you or use your
                                        data for any other purpose.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Contact data (email address) <br />
                                        (b) Demographic/Profile data <br />
                                        (c) Technical data (UID, setting cookies) <br />
                                        (d) Pseudonymised data
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Ad exposure and measurement</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We may match your personal data against our clients or
                                        trusted partners information to determine if you use any
                                        of their products or services and/or if you have been
                                        exposed to any of their advertisements. This helps our
                                        clients understand more about consumers behaviour when ads
                                        are displayed to them.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Identity (UID)<br />(b) Contact (email address)<br />(c)
                                        Demographic/Profile<br />(d) Technical data<br />(e)
                                        Pseudonmyised data<br />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Legal/public authority disclosure</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We will not transfer any Identity or Contact data to third
                                        parties unless we have first received your consent and no
                                        third parties may contact you or use your data for any
                                        other purpose. Though we make every effort to preserve
                                        your privacy, we may be required to disclose your personal
                                        data in response to lawful requests by public authorities,
                                        including to meet national security or law enforcement
                                        requirements; or to comply with a subpoena or other legal
                                        process, when we believe in good faith that disclosure is
                                        necessary to protect our rights, to protect your safety or
                                        the safety of others, to investigate fraud or breaches of
                                        our site terms, or to respond to a government request.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        (a) Identity<br />(b) Contact<br />(c)
                                        Demographic/Profile<br />(d) Technical data<br />(e)
                                        Pseudonmyised data
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <NavLink>Fraudulent and quality checking</NavLink>
                                </td>
                                <td>
                                    <div>
                                        We use multiple Technology data for quality control,
                                        validation, and fraud detection and prevention purposes,
                                        including assisting us in ensuring the integrity of survey
                                        results. Please see the section entitled ‘What other
                                        tracking technologies do we use for surveys you
                                        participate in and for other purposes?’ for more on this.
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div>(a) Technical data</div>
                                        <div>(b) Pseudonymised data</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <h4>6. How we collect data</h4>
            <div>
                For Surveys, we deploy client cookie tracking, which also includes
                cookies for Ad effectiveness campaigns As a part our standard
                operating procedure, we may share your unique panelist ID (UID) with
                our clients, who may write, set or read cookies or use other Technical
                data about you from multiple locations, including our own servers or
                systems. Your participation in surveys is accounted for an agreement
                in storing of your UID or associated with those Technical data to
                allow us and/or our clients to conduct online ad-effectiveness
                campaigns, track your visits to websites, use your Demographic/Profile
                data to create look-a-like segments and/or gain other online insights
                about you. In case of your interaction with the online ad or
                promotion, our client will send your UID and the specific survey to us
                and we may provide you with the opportunity to complete the survey
            </div>

            <h3>Other Technologies</h3>
            <h4>Automated technologies or interactions.</h4>
            <div>
                Post becoming a member, when you interact with our website, Your
                Technical data will be automatically collected which would entail your
                equipment, browsing actions and patterns. The way we collect this
                technical data is using cookies, server logs, digital finger printing
                technologies and other similar technologies. Also, WEBSAMP may receive
                your technical data from other websites employing our cookies. Please
                see our cookie policy for further details.
            </div>
            <h4>Server log files:</h4>

            <div>
                WEBSAMP may additionally collect Internet protocol (IP) addresses,
                browser type, internet service provider (ISP), referring/exit pages,
                operating system, date/time stamp, and/or clickstream data. We may
                combine this Technical data with other information collected about you
                for the purposes of improving our services, site functionality and
                collecting analytical data.
            </div>
            <h5>Fingerprinting technologies:</h5>
            <div>
                At WEBSAMP, digital Fingerprinting technologies assign a unique
                identifier or "Machine-ID" to a user's computer to identify and track
                your device (“Technology"). The Technology will analyse publicly
                available information and data obtained from your computer's web
                browser and from other publicly available data points, including the
                technical settings of your computer, the characteristics of your
                computer, and its IP Address, to create a unique identifier, which
                will be assigned to your device. For us, this technology is put to use
                for quality control, validation, and fraud detection and prevention
                purposes, including assisting us in ensuring the integrity of survey
                results. We follow appropriate technical and operational processes and
                procedures will be put in place to ensure that the Technology is safe,
                secure and does not cause undue privacy or data security risks and the
                Technology will be used and distributed in a professional and ethical
                manner and in accordance with (a) this privacy notice, (b) any other
                statements and/or disclosures made to you as a member of the community
                and (c) applicable laws and market research codes of practice. At any
                given point, if we spot an event where any unethical conduct is
                discovered in connection with the use of the Technology, or that the
                Technology is being used in a manner that is inconsistent with privacy
                notice, immediate action will be taken to prohibit such unethical
                conduct and to ensure the proper administration of the Technology.
            </div>
            <h5>Advertising</h5>
            <div>
                WEBSAMP partners with multiple third parties to either display
                advertising on our site or to manage our advertising on other sites.
                Our third-party partners may use technologies such as cookies to
                gather information about your activities on their site and other sites
                in order to provide you advertising based upon your browsing
                activities and interests.
            </div>
            <h5>Digital Fingerprinting</h5>
            <div>
                At WEBSAMP, digital Fingerprinting technologies assign a unique
                identifier or "Machine-ID" to a user's computer to identify and track
                your computer. We will not use digital fingerprinting technology (the
                “Technology") to collect your Identity or Contact data, or track your
                online activities; and will not disrupt or interfere with the use or
                control of your computer or alter, modify or change its settings or
                functionality. In certain cases, in specific market research programs,
                we will use the Technology to assist our clients in ensuring the
                integrity of survey results. The Technology will analyse publicly
                available information and data obtained from your computer's web
                browser and from other publicly available data points, including the
                technical settings of your computer, the characteristics of your
                computer, and its IP Address, to create a unique identifier, which
                will be assigned to your computer. The unique identifier will be an
                alpha-numeric ID In order to assist our clients in protecting and
                ensuring the integrity of survey results, we may; (a) link or
                associate your unique identifier to you and any of your personal data;
                (b) share your unique identifier with our clients and with other
                sample or panel providers; and (c) receive or obtain a unique
                identifier linked to you from a third party, including without
                limitation a sample or panel provider or our client, however we will
                only provide such information to any third parties (including our
                clients) on an aggregated and anonymised or pseudonymised basis. In
                addition, any unique identifier(s) received or obtained by us and
                linked to you will be protected in accordance with this privacy notice
                and if required by law, we will ask for your consent in advance. As
                WEBSAMP, we do everything we can to ensure that the Technology is
                safe, secure and does not cause undue privacy or data security risks
                and we shall use and distribute the Technology in a professional and
                ethical manner and in accordance with (a) this privacy notice, (b) any
                other statements and/or disclosures made by us to you and (c)
                applicable laws and market research codes of practice. At any given
                point, if we spot an event where any unethical conduct is discovered
                in connection with the use of the Technology, or that the Technology
                is being used in a manner that is inconsistent with privacy notice,
                immediate action will be taken to prohibit such unethical conduct and
                to ensure the proper administration of the Technology.
            </div>
            <h4>Social Media Widgets</h4>
            <div>
                As an industry wide best practice, WEBSAMP’s website includes Social
                Media Features, such as the Facebook Like button (and Widgets, such as
                the Share This Button or interactive mini-programs that run on our
                site). These Features may collect your IP address; which page you are
                visiting on our site; and may set a cookie to enable the Feature to
                function properly. Social Media Features and Widgets are either hosted
                by a third party or hosted directly on our site. Your interactions
                with these Features are governed by the privacy notice of the company
                providing it.
            </div>
            <h4>7. Terms & Conditions related to Survey Completion</h4>
            <p>
                <strong>By participating in surveys on our platform, you agree to the
                    following terms and conditions:</strong>
            </p>

            <ul>
                <li>
                    (i) Survey Profiling: Each survey has specific criteria that
                    determine user eligibility. If a survey determines that you do not
                    match its required profile at any stage, the survey may be
                    terminated.
                </li>
                <li>
                    (ii) Incomplete Surveys: If your survey is terminated before
                    completion due to not matching the profiling criteria, you will not
                    be eligible to receive any compensation for that survey.
                </li>
                <li>
                    (iii) Eligibility: Compensation is only provided for surveys that
                    are fully completed and successfully submitted as per the survey's
                    requirements.
                </li>
            </ul>

            <h4>8. Terms and Conditions for Third Party Login</h4>
            <h5>Automated technologies or interactions.</h5>
            <div>
                We offer third-party login options for your convenience, including
                Twitter, Google, LinkedIn, and Apple (hereinafter referred to as
                "Third-Party Login Providers"). By using these Third-Party Login
                Providers to access our website, you agree to the following terms and
                conditions: <br />
                By utilizing any Third-Party Login Provider to access our website, you
                agree to abide by these Terms and Conditions. If you do not agree to
                these terms, please refrain from using the Third-Party Login options.
                <br />
                When you use a Third-Party Login Provider to access our website, we
                may collect personal information associated with your account on that
                platform. This may include your name, email address, profile picture,
                and other details provided by the Third-Party Login Provider. <br />
                The information collected through Third-Party Login Providers will be
                used in accordance with this Privacy Policy. We will not share, sell,
                or distribute your information without your consent, except as
                required by law.
                <br />
                Your use of Third-Party Login Providers is subject to the terms and
                conditions and privacy policies of the respective providers. We
                encourage you to review these documents before using Third-Party Login
                on our website.
            </div>

            <ul>
                <li>
                    • Twitter:
                    <NavLink to="https://x.com/en/tos"> Twitter Terms of Service </NavLink>
                </li>
                <li>
                    • Google:
                    <NavLink to="https://policies.google.com/terms">
                        Google Terms of Service and Google Privacy Policy</NavLink>
                </li>
                <li>
                    • LinkedIn:
                    <NavLink to="https://www.linkedin.com/legal/user-agreement">
                        LinkedIn User Agreement</NavLink>
                </li>
                <li>
                    • Apple:
                    <NavLink to="https://www.apple.com/legal/internet-services/itunes/">
                        Apple Media Services Terms and Conditions</NavLink>
                </li>
            </ul>
            <p>
                You are responsible for maintaining the confidentiality of your login
                credentials and for any activities that occur under your account. If
                you suspect any unauthorized use of your account, you must notify us
                immediately.
            </p>

            <p>
                You may disconnect your account from any Third-Party Login Provider at
                any time. However, doing so may limit your access to certain features
                or services on our website. <br />
                We reserve the right to modify these terms and conditions at any time.
                Any changes will be effective immediately upon posting on our website.
                Your continued use of Third-Party Login after any such modifications
                constitutes your acceptance of the new terms. <br />
                We are not responsible for any issues, losses, or damages arising from
                your use of Third-Party Login Providers, including any unauthorized
                access to your account due to the Third-Party Login Providers. <br />
                Your use of Third-Party Login Providers is subject to the privacy
                policies of the respective providers. We encourage you to review these
                policies to understand how your information is handled by them:
            </p>

            <ul>
                <li>
                    • Twitter:
                    <NavLink to="https://x.com/en/privacy">
                        Twitter Twitter Privacy Policy
                    </NavLink>
                </li>
                <li>
                    • Google:
                    <NavLink to="https://policies.google.com/privacy">
                        Google Privacy PolicyPrivacy Policy</NavLink>
                </li>
                <li>
                    • LinkedIn:
                    <NavLink to="https://www.linkedin.com/legal/privacy-policy">
                        LinkedIn Privacy Policy</NavLink>
                </li>
                <li>
                    • Apple:
                    <NavLink to="https://www.apple.com/legal/privacy/">
                        Apple Privacy Policy</NavLink>
                </li>
            </ul>

            <h5>
                9. Terms and Conditions for Third-Party Gift Vouchers and Payment
                Gateway
            </h5>
            <div>
                By accessing or using third-party gift vouchers and payment gateways
                on our website, you agree to be bound by these Terms and Conditions.
                If you do not agree with these terms, please do not use these
                services.
            </div>
            <div>
                We may offer gift vouchers provided by third-party vendors
                (hereinafter referred to as "Gift Voucher Providers"). The following
                terms apply:
            </div>
            <ul>
                <li>
                    <strong>• Issuance:</strong> Gift vouchers may be issued as part of
                    promotional offers, rewards for completing surveys, or other
                    campaigns. The issuance of gift vouchers is subject to the terms set
                    by the Gift Voucher Providers.
                </li>
                <li>
                    <strong>• Redemption:</strong> Gift vouchers can only be redeemed as
                    per the terms and conditions set by the Gift Voucher Providers. We
                    are not responsible for the fulfilment, quality, or availability of
                    goods or services redeemed using the gift vouchers.
                </li>
                <li>
                    <strong> • Expiration:</strong> Gift vouchers may have expiration
                    dates, as determined by the Gift Voucher Providers. It is your
                    responsibility to use the vouchers before they expire. We will not
                    be liable for any expired or unused vouchers.
                </li>
                <li>
                    <strong> • Transferability:</strong> Gift vouchers are
                    non-transferable, non-refundable, and cannot be exchanged for cash
                    unless explicitly stated by the Gift Voucher Providers.
                </li>
                <li>
                    <strong> • Lost or Stolen Vouchers:</strong> We are not responsible
                    for lost, stolen, or damaged gift vouchers. Once issued, the voucher
                    is the responsibility of the recipient. Third-Party Payment Gateways
                    Payments made on our website may be processed through third-party
                    payment gateways (hereinafter referred to as "Payment Providers").
                    The following terms apply:
                </li>
                <li>
                    <strong>• Payment Processing:</strong> All payment transactions will
                    be processed securely through our Payment Providers. By using our
                    website, you agree to comply with the terms and conditions of the
                    Payment Providers.
                </li>
                <li>
                    <strong>• Payment Information:</strong> You are required to provide
                    accurate and complete payment information. We do not store your
                    payment details; all payment information is managed securely by the
                    Payment Providers.
                </li>
                <li>
                    <strong>• Transaction Fees:</strong> Any fees associated with
                    payment processing (e.g., transaction fees, currency conversion
                    fees) will be borne by the user unless otherwise stated.
                </li>
                <li>
                    <strong>• Refunds and Disputes:</strong> Any refunds, chargebacks,
                    or payment disputes will be handled in accordance with the terms set
                    by the Payment Providers. We are not responsible for resolving
                    disputes between you and the Payment Providers.
                </li>
                <li>
                    <strong>• Security:</strong> While we strive to use reputable
                    Payment Providers, we do not guarantee the security of payment
                    transactions. You acknowledge and agree that you use payment
                    gateways at your own risk.
                </li>
            </ul>
            <p>
                We are not liable for any loss, damage, or inconvenience arising from
                your use of third-party gift vouchers or payment gateways, including
                but not limited to issues related to voucher redemption, payment
                processing errors, or unauthorized transactions. <br />
                We reserve the right to modify these terms and conditions at any time.
                Any changes will be effective immediately upon posting on our website.
                Your continued use of third-party gift vouchers and payment gateways
                after any such modifications constitutes your acceptance of the new
                terms.
            </p>

            <h5>10. Sharing your Personal Data</h5>
            <div>
                The Personal Identifiable Information (PII) collected by WEBSAMP is
                solely used for market research purposes and not for any other
                reasons. We do not sell or share the PII of our panel members or
                individuals not registered with us without their consent. <br />
                We may share your PII with service providers who assist us in our
                business activities, such as managing rewards, customer support, email
                delivery, or order fulfillment. These companies are only authorized to
                use your information as needed to provide their services and help us
                meet our obligations to you. <br />
                In certain circumstances, WEBSAMP may need to disclose personal data
                in response to legal requests from public authorities. We may also
                release your PII if required by law, such as in response to a subpoena
                or similar legal process, or if we believe disclosure is necessary to
                protect our rights, ensure your safety or the safety of others,
                investigate fraud, or respond to government inquiries.
            </div>
            <h5>11.Does WEBSAMP transfer your personal data to other countries?</h5>
            <div>
                WEBSAMP is an India first company and your personal data will only be
                transferred across our offices and our trusted service providers. We
                follow all the data privacy laws as applicable in India.
            </div>
            <h5>12. Your Rights</h5>
            <h6>
                How do I access my information; use the member services area and/or
                update, correct or delete your information?
            </h6>
            <div>
                Based on individually made requests, WEBSAMP will adhere and provide
                you with information about whether we hold any of your personal data.
                This will allow you to access, correct, or request deletion of your
                personal data, or terminate your membership by logging into your
                Member Account. We recommend following the appropriate directions,
                your information should be automatically updated in our database. For
                these purposes, and if you are unable to correct your personal data
                yourself via the Account, you may write to us at the postal address
                found at the end of this privacy notice, or contact us here
                vijay.rajput@continuuminsights.com. We will respond to all requests
                within a reasonable timeframe.
            </div>
            <p>
                <strong>ou have the following rights regarding your personal
                    information:</strong>
            </p>
            <ul>
                <li>
                    • Access: You can request access to the personal information we hold
                    about you.
                </li>
                <li>
                    • Correction: You can request that we correct any inaccurate or
                    incomplete information.
                </li>
                <li>
                    • Deletion: You can request that we delete your personal
                    information, subject to legal obligations.
                </li>
                <li>
                    • Disconnection: You can disconnect your account from any
                    Third-Party Login Provider at any time, though this may limit your
                    access to certain features on our website.
                </li>
            </ul>
            <h5>How do I terminate my influencers panel membership?</h5>
            <div>
                In a likely scenario that you decide to end your membership with us or
                to require us to cease processing your personal data, you may
                discontinue your membership by going to “Account” and selecting
                “Unsubscribe Options” on the bottom left-hand corner, then "Cancel
                your account". We recommend following the appropriate directions, as
                that would enable you to have your record marked as "do not contact",
                and you will no longer receive communications. In addition, you will
                forfeit any incentive balance that has not been requested as of the
                time you opt out. As an alternative, you may send an email directly
                using the Contact us form requesting to be removed. Email links are
                provided on the site so that you may contact us directly with any
                questions or concerns you may have. Each email we receive is read and
                responded to individually. In most cases it will take 2 to 3 days to
                process this change, but please allow up to two full weeks for your
                status to be finalised. Please note that you may continue to receive
                communications during this short period after changing your
                preferences while our systems are fully updated.
            </div>
            <h4>Data Retention</h4>
            <div>
                As a WEBSAMP member, If you terminate your membership, WEBSAMP will no
                longer use your personal data to contact you, but in accordance with
                our backup procedures, we will retain your personal data until your
                personal data are eventually destroyed in accordance with our data
                retention and destruction policies and we will continue to employ the
                security procedures and technologies to keep your personal data safe.
            </div>
            <h4>How can I ask a question or make a complaint?</h4>
            <div>
                We believe in feedback and take pride in providing the best services
                to our members and clients. If you have a question or complaint about
                the use or disclosure of your personal data to our Privacy Contact. We
                will investigate and attempt to resolve any complaints or disputes
                regarding the use or disclosure of your personal data within 30 days
                of receiving your complaint.
            </div>
            <h4>Your other rights</h4>
            <h5>You may:</h5>
            <div>
                As member request access to your personal data and we may conduct ID
                checks before we can respond to your request. As a member, have your
                personal data erased, corrected or restricted if it is inaccurate or
                requires updating. You may also have the right under certain
                circumstances to request deletion of your personal data; however, this
                is not always possible due to legal requirements and other obligations
                and factors. You can update your account information via your Account
                or by contacting us at the address given below. object to the
                processing your personal data if we are not using your personal data
                for the purposes set out in this privacy policy.
            </div>
            <h5>How to exercise your rights:</h5>
            <div>
                You will not usually need to pay a fee to exercise any of the above
                rights. However, we may charge a reasonable fee if your request is
                clearly unfounded or excessive. Alternatively, we may refuse to comply
                with the request in such circumstances.
            </div>
            <div>
                If you wish to exercise your rights, you may contact us using the
                details set out below within the section called ‘Contact Us’. We may
                need to request specific information from you to confirm your identity
                before we can process your request. Once in receipt of this, we will
                process your request without undue delay and within one month. In some
                cases, such as with complex requests, it may take us longer than this
                and, if so, we will keep you updated.
            </div>
            <h5>13. Children’s Privac</h5>
            <div>
                WEBSAMP under no circumstance will not knowingly collect any
                information from any child under the age of 18 without permission from
                his/her parents, legal guardian or an adult responsible for the care
                of the child. Eligible ‘young people’ (individuals aged between 18 and
                20) may be invited to participate in our Teens panel from time to
                time. We comply with the local authorities for the same. If you are a
                parent or guardian of a child under 18 and become aware that your
                child has provided personal data to us and you are not aware of such
                processing, please contact us as described in this Privacy Notice and
                we will take reasonable steps immediately to remove any such
                information.
            </div>

            <h4>14. Security and storage of information</h4>
            <div>
                We have put in place appropriate security measures to prevent your
                personal data from being accidentally lost, used or accessed in an
                unauthorised way, altered or disclosed. In addition, we limit access
                to your personal data to those employees, agents, contractors and
                other third parties who have a business need to know. They will only
                process your personal data on our instructions, and they are subject
                to a duty of confidentiality. <br />
                If we become aware of any loss, misuse, alteration of personal data we
                will work closely with our IT team, DPO and other parties as necessary
                to investigate the incident at hand. We have put into place the
                relevant procedure and policies in place to investigate, mitigate and
                report (when needed to relevant parties) such instances.
            </div>
            <h4>15.Data Protection Officer</h4>
            <div>
                WEBSAMP has appointed a Data Protection Officer (DPO) who is
                responsible for overseeing questions in relation to this privacy
                policy. If you have any questions about our privacy practices,
                including how we handle your personal information, or if you want to
                exercise any of your rights under applicable data protection laws, you
                can contact our Data Protection Officer using the following contact
                details:

                <ul>
                    <li>Name of Data Protection Officer: Vijay Rajput</li>
                    <li>
                        Email:
                        <NavLink to="mailto:vijay.rajput@continuuminsights.com"
                            >vijay.rajput@continuuminsights.com</NavLink>
                    </li>
                    <li>Phone: +91-9717866086</li>
                </ul>
                <p>
                    Please include "Privacy Policy" in the subject line of your email or
                    letter and clearly state your request or concern. We will do our
                    best to address your inquiry in a timely manner.
                </p>
            </div>
            <h5>
                16. Changes to the privacy notice and your duty to inform us of
                changes
            </h5>
            <div>
                This version was last updated on the date at the top of this privacy
                policy. As WEBSAMP, we reserve the right to modify this privacy
                statement at any time, so please review it frequently. If we do decide
                to change our privacy notice, we will post those changes to this
                privacy statement on the homepage, or other places we deem appropriate
                so that you are aware of what information we collect, how we use it,
                and under what circumstances, if any, we disclose it. If we make
                material changes to this policy, we will notify you here, by email, or
                by means of a notice on our home page prior to the change becoming
                effective. It is important that the personal data we hold about you is
                accurate and current. Please keep your Account details updated if your
                personal data changes during your relationship with us.
            </div>
        </div>
    </div>
    </>
  );
}