import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./landing-screen/home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./landing-screen/about";
import LandingLayout from "./layouts/LandingLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardHome from "./dashboard/Home";
import TermService from "./landing-screen/TermService";
import PrivacyPolicy from "./landing-screen/PrivacyPolicy";
import ProfileSurvey from "./dashboard/ProfileSurvey";
import PersonalDetails from "./dashboard/PersonalDetails";
import Transaction from "./dashboard/Transaction";
import Referral from "./dashboard/Referral";
import SignOut from "./dashboard/SignOut";
import SurveyPage from "./dashboard/Survey";
import { useSelector } from "react-redux";
import HowItWorks from "./landing-screen/HowItWorks";
import ReferralPage from "./landing-screen/ReferralPage";
import FAQ from "./landing-screen/FAQ";
import Earnings from "./dashboard/Earnings";
import Reward from "./dashboard/Reward";
import Prescreener from "./dashboard/Prescreener";
import ThankYou from "./dashboard/ThankYou";
import NotQualified from "./dashboard/NotQualified";
import { useNavigate } from "react-router-dom";
import CaptureResponse from "./dashboard/CaptureResponse";

function App() {

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedInres, setisLoggedInres] = useState(false); 
  const authStatus = useSelector((state) => state.auth);
  const isLoggedIn = authStatus.isLoggedIn;
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authData = urlParams.get("authData");
    
    if (authData) {
      const parsedAuthData = JSON.parse(decodeURIComponent(authData));
      if (parsedAuthData?.is_success) {
        localStorage.setItem("user", JSON.stringify(parsedAuthData));
        const token = parsedAuthData?.data?.token;
       localStorage.setItem("token", JSON.stringify(token));
        setisLoggedInres(true);
        navigate("/dashboard"); 
      }
    } else {
      const storedAuthData = localStorage.getItem("user");
      const authDataObject = storedAuthData ? JSON.parse(storedAuthData) : null;
      
      if (authDataObject?.is_success) {
        setisLoggedInres(authDataObject?.is_success);
      } else {
        setisLoggedInres(isLoggedIn);
      }
    }
    
    setIsLoading(false); 
  }, [isLoggedIn, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    
       
            <Routes>
              {isLoggedInres ? (
                <>
                <Route element={<DashboardLayout />}>
                  <Route path="/dashboard" element={<DashboardHome />} />
                  <Route path="/profile-survey" element={<ProfileSurvey />} />
                  <Route path="/personal-details" element={<PersonalDetails />} />
                  <Route path="/transaction" element={<Transaction />} />
                  <Route path="/refer" element={<Referral />} />
                  <Route path="/survey" element={<SurveyPage />} />
                  <Route path="/earning" element={<Earnings />} />
                  <Route path="/reward" element={<Reward />} />
                  <Route path="/how-referral-works" element={<ReferralPage />} />
                  <Route path="/sign-out" element={<SignOut />} />
                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />
                </Route>
                <Route path="/pre-screener" element={<Prescreener />} />
                <Route path="/not-qualified" element={<NotQualified />} />
                 <Route path="/thank-you" element={<ThankYou />} />
                 <Route path="/post-survey" element={<CaptureResponse />} />
                 </>
              ) : (
                <Route element={<LandingLayout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/about-us" element={<About />} />
                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route path="/how-referral-works" element={<ReferralPage />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/term-service" element={<TermService />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
              )}
            </Routes>
        
        );
}

// function App() {
//   // const [isLoggedInres, setisLoggedInres] = useState(null);
//   // const authStatus = useSelector((state) => state.auth);
//   // const isLoggedIn = authStatus.isLoggedIn;

//   // useEffect(() => {
//   //   const storedAuthData = localStorage.getItem("user");
//   //   if (storedAuthData) {
//   //     const authDataObject = JSON.parse(storedAuthData);
//   //     if (authDataObject?.is_success) {
//   //       setisLoggedInres(authDataObject?.is_success);
//   //     }else if (storedAuthData) {
//   //       setisLoggedInres(true);
//   //     }
      
//   //   } else {
//   //     setisLoggedInres(isLoggedIn);
//   //   }
//   // }, [isLoggedIn]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLoggedInres, setisLoggedInres] = useState(false); 
//   const authStatus = useSelector((state) => state.auth);
//   const isLoggedIn = authStatus.isLoggedIn;
//   const navigate = useNavigate();

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const authData = urlParams.get("authData");
    
//     if (authData) {
//       const parsedAuthData = JSON.parse(decodeURIComponent(authData));
//       if (parsedAuthData?.is_success) {
//         localStorage.setItem("user", JSON.stringify(parsedAuthData));
//         setisLoggedInres(true);
//         navigate("/dashboard");  // navigate to dashboard after setting local storage
//       }
//     } else {
//       const storedAuthData = localStorage.getItem("user");
//       const authDataObject = storedAuthData ? JSON.parse(storedAuthData) : null;
      
//       if (authDataObject?.is_success) {
//         setisLoggedInres(authDataObject?.is_success);
//       } else {
//         setisLoggedInres(isLoggedIn);
//       }
//     }
    
//     setIsLoading(false); 
//   }, [isLoggedIn, navigate]);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <Router>
//       <Routes>
//         {isLoggedInres ? (
//           <Route element={<DashboardLayout />}>
//             <Route path="/dashboard" element={<DashboardHome />} />
//             <Route path="/profile-survey" element={<ProfileSurvey />} />
//             <Route path="/personal-details" element={<PersonalDetails />} />
//             <Route path="/transaction" element={<Transaction />} />
//             <Route path="/refer" element={<Referral />} />
//             <Route path="/survey" element={<SurveyPage />} />
//             <Route path="/earning" element={<Earnings />} />
//             <Route path="/reward" element={<Reward />} />
//             <Route path="/how-referral-works" element={<ReferralPage />} />
//             <Route path="/sign-out" element={<SignOut />} />
//             <Route path="/how-it-works" element={<HowItWorks />} />
//             <Route path="/pre-screener" element={<Prescreener />} />
//             <Route path="/not-qualified" element={<NotQualified />} />
//             <Route path="/thank-you" element={<ThankYou />} />
//             <Route path="*" element={<Navigate to="/dashboard" />} />
//           </Route>
//         ) : (
//           <Route element={<LandingLayout />}>
//             <Route path="/" element={<Home />} />
//             <Route path="/about-us" element={<About />} />
//             <Route path="/how-it-works" element={<HowItWorks />} />
//             <Route path="/how-referral-works" element={<ReferralPage />} />
//             <Route path="/faq" element={<FAQ />} />
//             <Route path="/term-service" element={<TermService />} />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//             <Route path="*" element={<Navigate to="/" />} />
//           </Route>
//         )}
//       </Routes>
//     </Router>
//   );
// }

export default App;
