import React from 'react'
import Transaction from "./Transactions"
import DashboardHeader from "./components/DashboardHeader";
import DashboardTabbar from "./components/DashboardTabbar";
const Earnings = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="container mt-4 min-h">
        <DashboardTabbar />
        <Transaction />
      </div>
    </div>
  )

}
export default Earnings