import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSurveyList } from "../redux/slice/surveySlice";
import "./dashboardStyle.css";
import AllSurveysList from "./components/AllSurveysList";
import DashboardProfileSurvey from "./components/DashboardProfile";
import { saveRefferalCode } from "../redux/slice/rewardSlice";
import DashboardBanner from "./components/DashboardBanner";
import DashboardTabbar from "./components/DashboardTabbar";

const DashbordHome = () => {
  const dispatch = useDispatch();
  const [surveys, setSurveys] = useState({ items: [] });
  const storedReferralCode = sessionStorage.getItem("referrerCode");
  const isApiCalled = useRef(false);

  // Fetch user details and set them in localStorage after successful dispatch
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on mount
  }, [dispatch]); // Run on mount and when dispatch changes

  useEffect(() => {
    // Fetch surveys only once and save referral code if it exists
    if (!isApiCalled.current) {
      dispatch(getUserSurveyList({ order_by: ["-created_at"] }));
      if (storedReferralCode) {
        dispatch(saveRefferalCode({ code: storedReferralCode.toUpperCase() }));
      }
      isApiCalled.current = true; 
    }
  }, [dispatch, storedReferralCode]);

  const assignedSurveys = useSelector(({ assignedSurveys }) => assignedSurveys);

  useEffect(() => {
    if (assignedSurveys.surveys) {
      setSurveys(assignedSurveys.surveys);
    }
  }, [assignedSurveys]);

  const limitedSurveys = {
    ...surveys,
    items:
      surveys.items && Array.isArray(surveys.items)
        ? surveys.items.slice(0, 5)
        : [],
  };

  return (
    <div>
      <DashboardBanner />
      <div className="container mt-4 min-h">
        <DashboardTabbar />
        <AllSurveysList
          surveys={limitedSurveys}
          fetching={assignedSurveys?.fetching}
        />
        <DashboardProfileSurvey />
      </div>
    </div>
  );
};

export default DashbordHome;
