import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const ThankYou = () => {
  const navigate= useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000); 
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <div className="alert alert-success">
            <h1 className="display-4">Thank you for completing the survey!</h1>
            <p className="lead">We appreciate your time and effort.</p>
            <p>You will be automatically redirected to the homepage in 5 seconds.</p>
            <NavLink to="/" className="btn btn-primary mt-3">Go Back to Home</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
