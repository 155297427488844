import React, { useEffect } from "react";

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <div className="how-it-works-page">
          <div className="container">
          <div className="heading-section pb-0 mt-4 mb-5">
            <h2 className="text-center"> Earn With Websamp</h2>
          </div>
            {/* First Row */}
            <div className="how-page-container">
              <div className="row align-items-center">
                <div className="col-md-6 mb-4">
                  <div className="text-part">
                    <span className="num">01</span>
                    <div className="text">
                      <h3 className="h5">Sign up</h3>
                      <p>
                        Want to make a difference? Sign up for WebSamp and share
                        your insights.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="img-part">
                    <div className="img">
                      <img src="./images/how-work1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Row */}
            <div className="how-page-container">
              <div className="row align-items-center d-flex flex-md-nowrap flex-wrap-reverse">
                <div className="col-md-6  mb-4">
                  <div className="img-part">
                    <div className="img">
                      <img src="./images/how-work2.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6  mb-4">
                  <div className="text-part">
                    <span className="num">02</span>
                    <div className="text">
                      <h3 className="h5"> Participate</h3>
                      <p>
                        Your opinion matters! Take our quick survey and help
                        shape the future.
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {/* Third Row */}
            <div className="how-page-container">
              <div className="row align-items-center">
                <div className="col-md-6 mb-4">
                  <div className="text-part">
                    <span className="num">03</span>
                    <div className="text">
                      <h3 className="h5">Earn money</h3>
                      <p>
                        Your time is valuable. Earn rewards for sharing your
                        opinion.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="img-part">
                    <div className="img">
                      <img src="./images/how-work3.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark py-5">
        <div className="container text-center">
          <h2 className="text-white my-5">Earn more by inviting friends</h2>
          <div className="row">
            <div className="col-md-4 step-container">
              <div className="step-icon">
                <span className="step-icon-number">1</span>
              </div>
              <h4 className="text-white">Register and Earn Rewards</h4>
              <p className="step-description">
                {" "}
                Create your profile with us by signing up using your Google
                account. After registering, you’ll gain access to a variety of
                surveys and studies. Complete these surveys to earn rewards.
              </p>
            </div>
            <div className="col-md-4 step-container">
              <div className="step-icon">2</div>
              <h4 className="text-white">Refer and Boost Your Earnings</h4>
              <p className="step-description">
                {" "}
                Refer friends, family, or colleagues to join our platform. For
                every survey they complete, you’ll earn 10% of their earnings.
              </p>
            </div>
            <div className="col-md-4 step-container">
              <div className="step-icon">3</div>
              <h4 className="text-white">Enjoy Unlimited Earning Potential</h4>
              <p className="step-description">
                {" "}
                There’s no limit to how much you can earn. The more people you
                refer, the more you can increase your earnings.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
