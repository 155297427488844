import React, { useEffect } from "react";

const ReferralPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="">
        <div className="container py-5 ">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="wrapper  text-start">
                <h2 className="mb-2"> Register and Earn Rewards</h2>

                <p>
                  Create your profile with us by signing up using your Google
                  account. After registering, you’ll gain access to a variety of
                  surveys and studies. Complete these surveys to earn rewards.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <div className="img fix-height-img">
                  <img src="./images/Sign-up-bro.png" alt="" />
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>

      <section className="bg-2 py-4">
        <div className="container py-md-5">
          <div className="row align-items-center d-flex flex-md-nowrap flex-wrap-reverse">
            <div className="col-md-6">
              <div className="wrapper">
                <div className="img fix-height-img">
                  <img src="./images/Refer-friend-amico.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="wrapper">
                <div className="   text-start">
                  <h2>Refer and Boost Your Earnings</h2>

                  <p>
                    Refer friends, family, or colleagues to join our platform.
                    For every survey they complete, you’ll earn 10% of their
                   reward points.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-4">
        <div className="container   py-md-5">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="wrapper">
                <div className="  text-start">
                  <h2>Enjoy Unlimited Earning Potential</h2>
                  <p>
                    There’s no limit to how much you can earn. The more people
                    you refer, the more you can increase your earnings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className=" col-md-6">
              <div className="wrapper  ">
                <div className="img fix-height-img">
                  <img src="./images/Money-income-rafiki.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReferralPage;
