import React from "react"
import { NavLink } from "react-router-dom";

const DashboardTabbar =()=>{

    return(
        <div className="dashboard-tabbar position-sticky fixed-top">
        <ul className="d-flex align-items-center justify-content-start">
          <li>
            <NavLink to="/profile-survey">Profile Survey</NavLink>
          </li>
          <li>
            <NavLink to="/survey"> Paid Survey</NavLink>
          </li>
          <li>
            <NavLink to="/refer">Refer & Earn</NavLink>
          </li>
          <li><NavLink to="/earning">Earnings</NavLink></li>
          <li>
            <NavLink to="/reward">Redeem</NavLink>
          </li>
         
          {/* <li>
            <NavLink to="/pre-screener" target="_blank">Redeem</NavLink>
          </li> */}
        </ul>
      </div>
    )

}

export default DashboardTabbar
